<template>
  <div id="myAddr" class="fixedMode">
    <div class="mainScroll">
      <div class="addrWrap loading" v-if="loading">
        <div class="recipientInfo gapLoad"></div>
        <p class="addr gapLoad">--</p>
      </div>
      <template v-else>
        <p class="noData" v-if="!addrList.length">暂无收货地址</p>
        <div
          class="addrWrap"
          :class="{ default: !index && item.isDefault }"
          v-for="(item, index) in addrList"
          @click="selectAddr(item)"
          :key="'addrList' + index"
        >
          <div class="recipientInfo">
            <p class="name">{{ item.name }}</p>
            <span class="mobile">{{ item.mobile }}</span>
          </div>
          <p class="addr">
            {{
              item.provinceName +
              item.cityName +
              item.districtName +
              " " +
              item.detailAddress
            }}
          </p>
          <span class="editBtn" @click.stop="editAddr(item.id)"></span>
        </div>
      </template>
    </div>
    <div class="bottomBtn">
      <button type="button" class="addAddrBtn" @click="editAddr('')">
        + 新增收货地址
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "myAddr",
  data() {
    return {
      loading: true,
      spuId: this.$route.query.spuId, //  说明是从订单而来
      sceneNo: this.$route.query.sceneNo,
      addrList: [] //  地址列表
    };
  },
  created() {
    this.getAddrList();
  },
  mounted() {},
  methods: {
    //  获取用户地址
    async getAddrList() {
      try {
        let res = await this.request.get("/api/scm/user/address/list", {
          page: 1,
          size: 100
        });
        if (res && res.length) {
          this.addrList = res;
          let tempIndex = -1;
          let updatedTime = 0;
          this.addrList.forEach((item, index) => {
            if (
              item.isDefault == 1 &&
              item.updatedTime &&
              new Date(item.updatedTime).getTime() > updatedTime
            ) {
              //  默认地址(可能会出现多个设置为默认的地址 取更新时间最新，后端这么算的)
              tempIndex = index;
              updatedTime = new Date(item.updatedTime).getTime();
            }
          });
          if (tempIndex > -1) {
            let temp = this.addrList.splice(tempIndex, 1);
            this.addrList.unshift(temp[0]);
            this.$store.commit("UPDATEDEFAULTADDR", temp[0]);
          }
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    //  编辑地址
    editAddr(id) {
      this.$router.push({
        path: "/h/scm/member/editAddr",
        query: {
          spuId: this.spuId,
          sceneNo: this.sceneNo,
          addrId: id || null
        }
      });
    },
    //  选中地址
    selectAddr(addr) {
      if (!this.spuId) return;
      sessionStorage.setItem(`${this.spuId}Addr`, JSON.stringify(addr));
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.fixedMode {
  background: #efefef;
}

.addrWrap {
  box-sizing: border-box;
  padding: 22px 150px 35px 85px;
  position: relative;
  background: #fff url(~@/assets/icons/myAddr-gray.png) 30px 40px / 24px auto
    no-repeat;
  border-bottom: 1px solid #dadbdd;
  .recipientInfo {
    font-size: 30px;
    line-height: 36px;
    min-height: 36px;
    padding: 12px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .mobile {
      font-size: 24px;
      color: #999;
      display: inline-block;
      margin-left: 25px;
    }
  }
  .addr {
    line-height: 30px;
    min-height: 60px;
    font-size: 24px;
    color: #333;
  }
  .editBtn {
    position: absolute;
    width: 60px;
    height: 60px;
    display: block;
    z-index: 1;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: url(~@/assets/icons/edit.png) center center / 24px auto
      no-repeat;
  }
  &.default {
    border: none;
    margin-bottom: 20px;
    background-image: url(~@/assets/icons/myAddr.png);
    .recipientInfo .name {
      padding-right: 85px;
      position: relative;
      &::after {
        content: "默认";
        display: inline-block;
        line-height: 32px;
        height: 32px;
        padding: 0 8px;
        border-radius: 32px;
        background: #feede8;
        color: #fa5220;
        font-size: 22px;
        position: absolute;
        top: 2px;
        right: 0;
      }
    }
  }
}

.bottomBtn {
  height: 150px;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  .addAddrBtn {
    width: 690px;
    height: 86px;
    display: block;
    border-radius: 69px;
    background: #fff;
    color: #ea3d39;
    font-size: 30px;
    margin-left: 15px;
    /* prettier-ignore */
    border: 1PX solid #ea3d39;
  }
}
.noData {
  padding-top: 30%;
  text-align: center;
  font-size: 30px;
  color: #666;
}
</style>